/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from '@mui/material/Box';
import { isEmpty } from 'lodash';
import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/lab/Alert';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';

import useCDPData from '../hooks/useCDPData';
import getCnfQuery from '../../../components/Header/utils/getCnfQuery';
import {
  EU_TRIALS_FILTERS,
  FDA_FILTERS,
  GRAPH_ENTITIES,
  FDA_LABEL_OPTIONS,
  EMA_LABEL_OPTIONS,
  selectedApprovalBarMarker,
  selectedMeetingMarker,
  TRIALS_FILTERS,
  EMA_FILTERS
} from '../const';
import {
  addLabels,
  geFDAFiltersPayload,
  getDefaultQuery,
  getDisplayableFilterValues,
  getGroupedFilter,
  getSelectedBarMarker,
  getTrialsGraphData,
  mapGraphEntityData,
  mergeOldFilterValues
} from '../utils';

import CTDocuments from './CTDocuments';
import FDADocuments from './FDADocuments';
import Loading from './Loading';
import FeedbackDialog from './Feedback';
import Filters from '../../../components/Filters';

import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import ApplicationCard from '../../../components/ApplicationCard';
import TimeSeriesGraphSkeleton from '../../../components/CustomComponents/Graphs/CustomTimeSeriesSkeleton';
import LabelOptions from './LabelOptions';
import SubmissionOptions from './SubmissionOptions';
import { getReferenceText } from '../../../components/Report/Widgets';
import { UrlParameters } from '../../../types/reactRouterDom';
import CdpSearchBar from '../CdpSearchBar';
import regulatoryInformationAPI from '../../../api/pages/Regulatory360';
import { apiDataToCardProps } from '../../SearchResults/constants';
import {
  FilterEmptyIcon,
  FilterFilledIcon,
  KeyDownIcon,
  SubHeaderPipeGreyIcon
} from '../../../assets/svgs/Icons';
import trimText from '../../../utils/trimText';
import styles from '../styles/CDPMain';
import reg360HeaderStyles from '../../RegulatoryInformation/Header/styles';
import applicationCardStyles from '../../SearchResults/styles/ApplicationCard.styles';
import InfoWindow from './InfoWindow';
import subheaderStyles from '../../../components/SubHeader/styles';
import MeetingOptions from './Meetings/MeetingOptions';
import { filterMeetingsByTypeAndCategory } from '../regInfoUtils';
import useReportingHandler from '../../../components/Report/hooks/useReportingHandler';
import ReportButton from '../../../components/Report/ReportButton';

// Lazy import TimeSeries
const TimeSeriesGraph = lazy(
  () => import('../../../components/CustomComponents/Graphs/CustomTimeSeries')
);

function a11yProps(index: string) {
  return {
    id: `filter-tab-${index}`,
    'aria-controls': `filter-tabpanel-${index}`
  };
}

const trialsFilterElementProps = {
  wrapperStyle: { pt: 1, pr: 4 },
  // eslint-disable-next-line react/jsx-no-useless-fragment
  titleElement: <></>,
  scrollContainerStyle: { height: '65vh' },
  selectedChipStyle: styles.trialsSelectedFilterChipStyle,
  radioGroupProps: {
    radioButtonStyle: styles.trialsRadioButtonStyle
  },
  applyButtonStyle: styles.trialsFilterApplyStyle
};

const fdaFilterElementProps = {
  wrapperStyle: { pt: 1, pr: 1 },
  // eslint-disable-next-line react/jsx-no-useless-fragment
  titleElement: <></>,
  scrollContainerStyle: { height: '65vh' },
  selectedChipStyle: styles.fdaSelectedFilterChipStyle,
  radioGroupProps: {
    radioButtonStyle: styles.fdaRadioButtonStyle
  },
  applyButtonStyle: styles.fdaFilterApplyStyle
};

const CDPMain: React.FC<any> = ({
  activeIngredient,
  applicationNumber,
  tradeName
}: {
  activeIngredient: string;
  applicationNumber: string;
  tradeName: string;
}) => {
  const { dispatch } = useContext<any>(GlobalStore);

  const {
    isLoading,
    isLoadingOverlays,
    errorMessage,
    responseData,
    getCDPData,
    selectSubmissionLabelOption,
    selectSubmissionLableGroup,
    clearAllSubmissionOption,
    selectMeetingCategory,
    selectMeetingType,
    clearAllMeetingOption
  } = useCDPData();
  const [applicationDetails, setApplicationDetails] = useState<any>({});
  const [donePageDataLoadingAnimation, setDonePageDataLoadingAnimation] = useState(false);
  const { module, number, source } = useParams<UrlParameters>();

  const [trialsFilters, setTrialsFilters]: any = useState();
  const [modalOpen, setModalOpen] = useState('');
  const [sourceSubmissionFilters, setFDAFilters]: any = useState();
  const [activeFilter, setActiveFilterType] = useState<'FDA' | 'TRIALS'>('TRIALS');

  const [nctId, setNCTId] = useState<string>('');
  const [selectedGraphEntityData, setGraphEntityData] = useState<any>(null);
  const [submission, setSubmission] = useState<string>('');
  const [approvalDate, setApprovalDate] = useState<any>(null);
  const [showDocs, setShowDocs] = useState<boolean>(false);
  const [showSBAsTrials, setShowSBATrials] = useState<boolean>(true);

  const [isFiltersOpen, setFiltersOpen] = useState<boolean>(false);
  const [isFeedbackOpen, setFeedbackOpen] = useState<boolean>(false);

  const [ctFiltersApplied, setCTFiltersApplied] = useState<boolean>(source === 'us'); // By default we are applying the filter
  const [fdaFiltersApplied, setFDAFiltersApplied] = useState<boolean>(false);
  const [fdaFilterValid, setFDAFilterValid] = useState<boolean>(true);
  const [ctFilterValid, setCTFilterValid] = useState<boolean>(true);

  const [warningMessage, setErrorMessage] = useState<string>('');

  const [labelOptionsAnchor, setLabelOptionAnchor] = useState<any>(null);
  const [submissionOptionAnchor, setSubmissionOptionAnchor] = useState<any>(null);
  const [meetingTypeOptionAnchor, setMeetingTypeOptionAnchor] = useState<any>(null);
  const [labelOptions, setLabelOptions] = useState<any>(
    source === 'us' ? FDA_LABEL_OPTIONS : EMA_LABEL_OPTIONS
  );

  const [meetingData, setMeetingData] = useState<any>(null);
  const [filteredMeetingsData, setFilteredMeetingsData] = useState<any>(null);
  const [selectedMeeting, setSelectedMeeting] = useState<any>(null); // Track selected meeting
  const [highlightedMeeting, setHighlightedMeeting] = useState<any>(null);
  const [isHighlighted, setIsHighlighted] = useState<any>(false);
  const { updateReportData } = useReportingHandler();
  const resetStateToDefault = () => {
    setDonePageDataLoadingAnimation(false);

    setTrialsFilters();
    setModalOpen('');
    setFDAFilters();
    setActiveFilterType('TRIALS');

    setNCTId('');
    setGraphEntityData(null);
    setSubmission('');
    setApprovalDate('');
    setShowDocs(false);
    setShowSBATrials(true);

    setFiltersOpen(false);
    setFeedbackOpen(false);

    setCTFiltersApplied(source === 'us'); // By default we are applying the filter
    setFDAFiltersApplied(false);
    setFDAFilterValid(true);
    setCTFilterValid(true);

    setErrorMessage('');

    setLabelOptionAnchor(null);
    setSubmissionOptionAnchor(null);
    setMeetingTypeOptionAnchor(null);
    setMeetingData(null);
    setSelectedMeeting({});
    setHighlightedMeeting({});
    setFilteredMeetingsData(meetingData);
    setIsHighlighted(null);
    setLabelOptions(source === 'us' ? FDA_LABEL_OPTIONS : EMA_LABEL_OPTIONS);
  };

  const graphRef = useRef();

  const initialTrialsFilter: any = useMemo(
    () =>
      (source === 'us' ? TRIALS_FILTERS : EU_TRIALS_FILTERS).map(fltr => ({
        ...fltr,
        options: []
      })),
    []
  );

  const initialSourceSubmissionFilter: any = useMemo(
    () => (source === 'us' ? FDA_FILTERS : EMA_FILTERS).map(fltr => ({ ...fltr })),
    []
  );

  const clearPreviouseSelectedMeetingMarkers = () => {
    const prevSelectedMarker = responseData?.barGraphData?.find(
      ({ customdata, marker }: { customdata: any; marker: any }) =>
        customdata?.[0]?.[0] === GRAPH_ENTITIES.REG_INFO_MEETING && marker.line
    );
    if (prevSelectedMarker) {
      delete prevSelectedMarker.marker.line;
      setIsHighlighted(false);
    }
  };

  const setCurrentMeetingMarker = (selectedMeeting: any) => {
    const selectedMarker = responseData.barGraphData
      .filter((data: any) => data.customdata?.[0]?.[0] === GRAPH_ENTITIES.REG_INFO_MEETING)
      .find(({ customdata }: { customdata: any }) => {
        const res_ = customdata?.[0][2].find(
          (_meeting: any) => _meeting?.uuid === selectedMeeting?.uuid
        );
        return !!res_;
      });
    if (selectedMarker) {
      selectedMarker.marker = { ...selectedMarker.marker, line: selectedMeetingMarker.line };
      setIsHighlighted(true);
    }
  };

  useEffect(() => {
    resetStateToDefault();
    if (activeIngredient && applicationNumber) {
      const query: any = getDefaultQuery(activeIngredient, true, source);
      const { result: cnfOfSelectedQuery }: any = getCnfQuery(query, 'cdp' as any);
      getCDPData(cnfOfSelectedQuery, {}, applicationNumber, {}, true, source);
    }
  }, [activeIngredient, applicationNumber]);

  useEffect(() => {
    const getData = async () => {
      if (!source || !number) return;
      const data = await regulatoryInformationAPI(source, number, 'application_details');
      let result;
      try {
        if (apiDataToCardProps[source]) {
          result = apiDataToCardProps[source]?.(data?.data?.body);
        }
      } catch (e) {
        result = data?.data?.body;
      }
      setApplicationDetails(result);
    };
    getData();
  }, [source, number]);

  useEffect(() => {
    if (responseData.trialsFilters) {
      let { trialsFilters: filters } = responseData;
      if (trialsFilters) {
        filters = mergeOldFilterValues(filters, trialsFilters);
      }
      setTrialsFilters(filters);
    }
  }, [responseData.trialsFilters]);

  useEffect(() => {
    if (responseData.sourceSubmissionFilters) {
      let { sourceSubmissionFilters: filters } = responseData;
      if (sourceSubmissionFilters) {
        filters = mergeOldFilterValues(filters, sourceSubmissionFilters);
      }
      setFDAFilters(filters);
    }
  }, [responseData.sourceSubmissionFilters]);

  useEffect(() => {
    if (responseData.meetingOptions) {
      const newMeetingData = filterMeetingsByTypeAndCategory(
        meetingData,
        responseData.meetingOptions
      );
      setFilteredMeetingsData(newMeetingData);
    }
  }, [JSON.stringify(responseData.meetingOptions)]);

  useEffect(() => {
    if (!isEmpty(highlightedMeeting) && filteredMeetingsData) {
      const { month, year, idx } = highlightedMeeting;
      if (idx !== null && idx !== undefined) {
        clearPreviouseSelectedMeetingMarkers();
        const selectedMeetingData = filteredMeetingsData?.[year]?.[month]?.[idx] || null;
        setCurrentMeetingMarker(selectedMeetingData);
      }
    }
    if (isEmpty(highlightedMeeting)) {
      clearPreviouseSelectedMeetingMarkers();
    }
  }, [highlightedMeeting]);

  const handleFilterApply = useCallback(
    (filters: any, id?: string) => {
      setDonePageDataLoadingAnimation(false);
      if (activeFilter === 'TRIALS' || id === 'TRIALS') {
        // GET CT FILTERS Payload
        const defaultQuery: any = getDefaultQuery(activeIngredient, false, source);
        const { dateRange, query } = getGroupedFilter(filters, defaultQuery);
        const { result: cnfOfSelectedQuery }: any = getCnfQuery(query, 'cdp' as any);

        // GET FDA Filters Payload
        const fdaFilterPayload = geFDAFiltersPayload(
          sourceSubmissionFilters || initialSourceSubmissionFilter
        );

        // Call the API and get the latest data
        getCDPData(
          cnfOfSelectedQuery,
          fdaFilterPayload,
          applicationNumber,
          dateRange,
          false,
          source
        );

        setTrialsFilters(filters);
        setCTFiltersApplied(Object.keys(query).length > Object.keys(defaultQuery).length);
      } else if (activeFilter === 'FDA' || id === 'FDA') {
        const defaultQuery: any = getDefaultQuery(activeIngredient, false, source);
        const { dateRange, query } = getGroupedFilter(
          trialsFilters || initialTrialsFilter,
          defaultQuery
        );

        const { result: cnfOfSelectedQuery }: any = getCnfQuery(query, 'cdp' as any);
        const fdaFiltersPayload = geFDAFiltersPayload(filters);

        getCDPData(
          cnfOfSelectedQuery,
          fdaFiltersPayload,
          applicationNumber,
          dateRange,
          ctFiltersApplied,
          source
        );
        setFDAFilters(filters);
        setFDAFiltersApplied(Object.keys(fdaFiltersPayload).length > 0);
      }
      setFiltersOpen(false);
    },
    [
      activeFilter,
      sourceSubmissionFilters,
      trialsFilters,
      initialTrialsFilter,
      initialSourceSubmissionFilter
    ]
  );

  const handleClearFilters = useCallback(() => {
    setDonePageDataLoadingAnimation(false);
    if (activeFilter === 'TRIALS') {
      const query: any = getDefaultQuery(activeIngredient, false, source);
      const { result: cnfOfSelectedQuery }: any = getCnfQuery(query, 'cdp' as any);

      const fdaFilterPayload = geFDAFiltersPayload(
        sourceSubmissionFilters || initialSourceSubmissionFilter
      );

      getCDPData(cnfOfSelectedQuery, fdaFilterPayload, applicationNumber, {}, false, source);
      setTrialsFilters(initialTrialsFilter);
      setCTFiltersApplied(false);
    } else {
      const defaultQuery: any = getDefaultQuery(activeIngredient, false, source);
      const { dateRange, query } = getGroupedFilter(
        trialsFilters || initialTrialsFilter,
        defaultQuery
      );
      const { result: cnfOfSelectedQuery }: any = getCnfQuery(query, 'cdp' as any);
      getCDPData(cnfOfSelectedQuery, {}, applicationNumber, dateRange, false, source);
      setFDAFilters(initialSourceSubmissionFilter);
      setFDAFiltersApplied(false);
    }
  }, [
    initialTrialsFilter,
    initialSourceSubmissionFilter,
    activeFilter,
    sourceSubmissionFilters,
    trialsFilters
  ]);

  const handleHorizontalBarClick = useCallback(
    (data: any) => {
      if (data.points && data.points[0]) {
        // Clear previous Clinical Trials bar selections
        const prevSelectedCTBar = responseData.barGraphData.find(
          ({ name }: { name: string }) => name === selectedGraphEntityData?.ctId
        );

        if (prevSelectedCTBar) {
          delete prevSelectedCTBar.marker.line;
        }

        // Clear previous Approval bar selections
        const prevSelectedApprovalBar = responseData.barGraphData.find(
          ({ customdata }: { customdata: any }) => {
            return source === 'us'
              ? customdata?.[0]?.[1] === selectedGraphEntityData?.submission
              : customdata?.[0]?.[8] === selectedGraphEntityData?.uniqueId;
          }
        );

        if (prevSelectedApprovalBar) {
          delete prevSelectedApprovalBar.marker.line;
        }

        // If selected bar is clinical trials bar
        if (data.points[0].customdata[0] === GRAPH_ENTITIES.CLINICAL_TRIAL) {
          const selectedBar = responseData.barGraphData.find(
            ({ name }: { name: string }) => name === data.points[0].label
          );
          if (selectedBar) {
            selectedBar.marker = { ...(selectedBar.marker || {}), ...getSelectedBarMarker() };
          }
          const ctData = {
            ctId: data.points[0].label,
            startDate: data.points[0].customdata[4],
            endDate: data.points[0].customdata[5],
            primaryCompletionDate: data.points[0].customdata[7],
            sampleSize: data.points[0].customdata[1],
            phase: data.points[0].customdata[2],
            studyTitle: data.points[0].customdata[3],
            nctIdInReview: data.points[0].customdata[6]
          };
          setGraphEntityData(ctData);
          setSubmission('');
          setMeetingData(null);
          setSelectedMeeting({});
          setHighlightedMeeting({});
          setIsHighlighted(null);
          setApprovalDate('');
          if (nctId) {
            setNCTId(ctData.ctId);
          } else {
            setShowDocs(false);
          }
        } else if (data.points[0].customdata[0] === GRAPH_ENTITIES.FDA_APPROVAL) {
          // If selected bar is FDA Approval
          const selectedBar = responseData.barGraphData.find(
            ({ customdata }: { customdata: any }) => {
              return source === 'us'
                ? customdata?.[0]?.[1] === data.points[0]?.customdata?.[1]
                : customdata?.[0]?.[8] === data.points[0]?.customdata?.[8];
            }
          );
          if (selectedBar) {
            selectedBar.marker = { ...selectedBar.marker, line: selectedApprovalBarMarker.line };
          }
          setNCTId('');
          const submissionData = {
            title: data.points[0].customdata[3],
            submission: data.points[0].customdata[1],
            startDate: data.points[0].customdata[5],
            endDate: data.points[0].customdata[4],
            actionDetails: data.points[0].customdata[2] || 'Action details: Unknown',
            isSubmission: true,
            uniqueId: data.points[0]?.customdata?.[8]
          };
          setGraphEntityData(submissionData);
          setMeetingData(data.points[0].customdata[7]);
          setFilteredMeetingsData(data.points[0].customdata[7]);
          if (submission) {
            setSubmission(submissionData.submission);
            setApprovalDate(data.points[0].customdata[4]);
          } else {
            setShowDocs(false);
          }
        } else if (data.points[0].customdata[0] === GRAPH_ENTITIES.REG_INFO_MEETING) {
          clearPreviouseSelectedMeetingMarkers();
          const selectedMeeting = data.points[0].customdata[1];
          setCurrentMeetingMarker(selectedMeeting);

          const submission = selectedMeeting.submission;
          const submissionGraphData = responseData.barGraphData.find((graphData: any) => {
            return (
              graphData?.customdata[0][0] === GRAPH_ENTITIES.FDA_APPROVAL &&
              graphData?.name === submission
            );
          });

          // higlight this submission
          submissionGraphData.marker = {
            ...submissionGraphData.marker,
            line: selectedApprovalBarMarker.line
          };

          const submissionMeetings = submissionGraphData?.customdata[0][7];
          const submissionData = {
            title: submissionGraphData.customdata[0][3],
            submission: submissionGraphData.customdata[0][1],
            startDate: submissionGraphData.customdata[0][5],
            endDate: submissionGraphData.customdata[0][4],
            actionDetails: submissionGraphData.customdata[0][2] || 'Action details: Unknown'
          };
          const month = new Date(selectedMeeting.start_date).toLocaleString('default', {
            month: 'long'
          });
          const year = new Date(selectedMeeting.start_date).getFullYear();
          setFilteredMeetingsData(submissionMeetings);
          setGraphEntityData(submissionData);
          setSelectedMeeting({ month, year });
          setHighlightedMeeting({ month, year });
        }
      }
    },
    [
      nctId,
      responseData.barGraphData,
      selectedGraphEntityData?.ctId,
      selectedGraphEntityData?.submission,
      selectedGraphEntityData?.endDate,
      selectedGraphEntityData?.uniqueId
    ]
  );

  const handleViewDocsClose = useCallback(() => {
    const prevSelectedBar = responseData.barGraphData.find(
      ({ name }: { name: string }) => name === selectedGraphEntityData?.ctId
    );
    if (prevSelectedBar) {
      delete prevSelectedBar.marker.line;
    }

    // Clear previous Approval bar selections
    const prevSelectedApprovalBar = responseData.barGraphData.find(
      ({ customdata }: { customdata: any }) =>
        source === 'us'
          ? customdata?.[0]?.[1] === selectedGraphEntityData?.submission
          : customdata?.[0]?.[8] === selectedGraphEntityData?.uniqueId
    );
    if (prevSelectedApprovalBar) {
      delete prevSelectedApprovalBar.marker.line;
    }

    // Clear previous meeting selection
    clearPreviouseSelectedMeetingMarkers();

    setGraphEntityData(null);
    setNCTId('');
    setSubmission('');
    setApprovalDate('');
    setShowDocs(false);
    setSelectedMeeting(null);
    setHighlightedMeeting(null);
    setFilteredMeetingsData(null);
  }, [
    responseData.barGraphData,
    selectedGraphEntityData?.ctId,
    selectedGraphEntityData?.submission,
    selectedGraphEntityData?.uniqueId
  ]);

  const handleViewDocsClick = useCallback(() => {
    if (selectedGraphEntityData?.ctId) {
      setNCTId(selectedGraphEntityData?.ctId as string);
    } else if (selectedGraphEntityData?.submission) {
      setSubmission(selectedGraphEntityData?.submission as string);
      setApprovalDate(selectedGraphEntityData?.endDate);
    }

    setShowDocs(true);
  }, [selectedGraphEntityData]);

  const mappedEntityData = useMemo(() => {
    let entityData = mapGraphEntityData(selectedGraphEntityData);
    if (source === 'us') {
      return entityData;
    }
    if (selectedGraphEntityData?.isSubmission)
      return {
        'Action Details': entityData['Action Details'],
        'Published Date': entityData['End Date']
      };
    return entityData;
  }, [selectedGraphEntityData]);

  const addRef = useCallback(
    (ref: any) => {
      graphRef.current = ref;
    },
    [showDocs]
  );

  const { submissionFlagOptions } = responseData;
  const { meetingOptions } = responseData;

  // Update Bargraph data when user switches the showTrials.
  const { barGraphData, nctIds } = useMemo(() => {
    const { barGraphData: bData, nctIds: ids } = getTrialsGraphData(responseData, showSBAsTrials);
    addLabels(
      bData,
      labelOptions.filter((opt: any) => opt.selected)
    );
    return { barGraphData: bData, nctIds: ids };
  }, [
    [responseData?.barGraphData],
    showSBAsTrials,
    selectedGraphEntityData,
    labelOptions,
    isHighlighted
  ]);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: 'FDA' | 'TRIALS') => {
      if (!fdaFilterValid || !ctFilterValid) {
        dispatch({
          type: GlobalActions.SET_ALERT,
          value: {
            status: true,
            message:
              'Filters were updated, please press the "Apply" button. before switching the tab'
          }
        });
        return;
      }
      setActiveFilterType(newValue);
    },
    [fdaFilterValid, ctFilterValid]
  );

  const handleDoneAnimation = useCallback(() => {
    setDonePageDataLoadingAnimation(true);
  }, []);

  const selectedCTFilterMapping = useMemo(() => {
    return getDisplayableFilterValues(trialsFilters);
  }, [trialsFilters]);

  const selectedFDAFilterMapping = useMemo(() => {
    return getDisplayableFilterValues(sourceSubmissionFilters);
  }, [sourceSubmissionFilters]);

  const handleFiltersClose = useCallback(() => {
    if (!fdaFilterValid || !ctFilterValid) {
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Filters were updated, please press the "Apply" button.' }
      });
      return;
    }
    setFiltersOpen(false);
  }, [fdaFilterValid, ctFilterValid]);

  const handlePDFViewClose = useCallback(() => {
    setShowDocs(false);
  }, []);

  const handleLabelOptionChange = useCallback(
    (selectedOption: any) => {
      const newLabelOptions = [...labelOptions];
      const index = newLabelOptions.findIndex((opt: any) => opt.id === selectedOption.id);
      newLabelOptions[index] = {
        ...newLabelOptions[index],
        selected: !newLabelOptions[index].selected
      };
      setLabelOptions(newLabelOptions);
    },
    [labelOptions]
  );

  const handleOnAlertClose = useCallback(() => setErrorMessage(''), []);
  const handleFeedback = useCallback(() => setFeedbackOpen(true), []);

  const handleAddToReport = useCallback((_: any, reportData: any) => {
      const fdaFilterPayload = geFDAFiltersPayload(
        sourceSubmissionFilters || initialSourceSubmissionFilter
      );
      const defaultQuery: any = getDefaultQuery(activeIngredient, false, source);

      const { dateRange, query } = getGroupedFilter(
        trialsFilters || initialTrialsFilter,
        defaultQuery
      );
      const { result: cnfOfSelectedQuery }: any = getCnfQuery(query, 'cdp' as any);

      const referenceText = getReferenceText(
        {
          module,
          source,
          application_number: number,
          href: window.location.href
        },
        'custom'
      );
      const reportStateValue = {
        templateType: 'custom',
        chartType: 'CDP_TIMELINE',
        sectionType: 'CHART',
        id: reportData?.reportId,
        style: {
          placement: {
            h: 56,
            w: 12,
            i: reportData?.reportId,
            minW: 8,
            moved: false,
            static: false,
            x: 0,
            y: 0
          },
          title: {},
          graphStyle: {},
          references: {
            text: `${referenceText?.replace('Reg360', '')} / CDP `,
            show: true,
            href: window.location.href
          }
        },
        data: {
          source,
          filters: {
            fda: fdaFilterPayload,
            trialsCNF: cnfOfSelectedQuery,
            trialsDateRange: dateRange,
            showTrialsInSBAS: showSBAsTrials
          },
          labels: labelOptions.filter((opt: any) => opt.selected),
          approvals: submissionFlagOptions.filter((opt: any) => opt.selected),
          activeIngredient,
          applicationNumber
        }
      };
      updateReportData(reportData?.id, reportStateValue);
    },
    [
      sourceSubmissionFilters,
      dispatch,
      trialsFilters,
      activeIngredient,
      applicationNumber,
      initialSourceSubmissionFilter,
      showSBAsTrials,
      labelOptions,
      submissionFlagOptions
    ]
  );

  const isFilterApplied = ctFiltersApplied || fdaFiltersApplied;
  const totalNumberOfSelectedFilters = useMemo(() => {
    let count = 0;

    count = Object.entries(selectedCTFilterMapping).reduce((acc, [, value]) => {
      return acc + (Array.isArray(value) ? value.length : 1);
    }, count);

    count = Object.entries(selectedFDAFilterMapping).reduce((acc, [, value]) => {
      return acc + (Array.isArray(value) ? value.length : 1);
    }, count);

    return count;
  }, [selectedCTFilterMapping, selectedFDAFilterMapping]);
  return (
    <Box height='100%' mt={2}>
      <Box
        width='100%'
        display='flex'
        height='100%'
        pb={2}
        overflow='hidden'
        position='relative'
        justifyContent='space-between'>
        <Box
          // Why VW unit? because, plotly does not split the view 50-50 when we use flex or % units
          width='calc(100vw - 48px)'
          display='flex'
          flexDirection='column'>
          <Box display='flex' alignItems='center'>
            <SubHeaderPipeGreyIcon sx={subheaderStyles.subHeaderPipeGreyIcon} />
            <Box ml={2}>
              <Typography sx={subheaderStyles.title}>CDP</Typography>
            </Box>
            <Divider
              orientation='vertical'
              sx={{ ...reg360HeaderStyles.divider, height: 42, marginX: 2 }}
            />
            <Box display='inline-block'>
              <ApplicationCard data={applicationDetails} />
            </Box>
            {donePageDataLoadingAnimation && (
              <Link
                onClick={() => {
                  setModalOpen('cdp');
                }}
                sx={reg360HeaderStyles.changeButton}>
                Switch Application
              </Link>
            )}
          </Box>
          <Box display='flex' alignItems='center' mt={2} mb={1}>
            <Box flex='1'>
              <Typography component='span' pr={1} fontWeight={600} color='gray.700' fontSize={14}>
                Customize Chart:
              </Typography>
              <Box
                onClick={(event: any) => setLabelOptionAnchor(event.currentTarget)}
                display='inline-flex'
                alignItems='center'
                sx={styles.trialDetailsButton}>
                Trial Details
                <KeyDownIcon sx={{ fontSize: 12, color: 'gray.800', ml: 1 }} />
              </Box>
              {source === 'us' && (
                <Box
                  onClick={(event: any) => setSubmissionOptionAnchor(event.currentTarget)}
                  display='inline-flex'
                  alignItems='center'
                  sx={styles.fdaApprovalsButton}>
                  FDA Approvals
                  <KeyDownIcon sx={{ fontSize: 12, color: 'gray.800', ml: 1 }} />
                </Box>
              )}
              {!isEmpty(meetingOptions) && (
                <Box
                  onClick={(event: any) => setMeetingTypeOptionAnchor(event.currentTarget)}
                  display='inline-flex'
                  alignItems='center'
                  sx={styles.trialDetailsButton}>
                  Meetings
                  <KeyDownIcon sx={{ fontSize: 12, color: 'gray.800', ml: 1 }} />
                </Box>
              )}
            </Box>
            {labelOptionsAnchor && (
              <LabelOptions
                options={labelOptions}
                onClose={() => setLabelOptionAnchor(null)}
                onMenuItemClick={handleLabelOptionChange}
                anchorEl={labelOptionsAnchor}
                open={!!labelOptionsAnchor}
              />
            )}
            {submissionOptionAnchor && source === 'us' && (
              <SubmissionOptions
                options={submissionFlagOptions}
                onClose={() => setSubmissionOptionAnchor(null)}
                onOptionSelect={selectSubmissionLabelOption}
                onGroupSelect={selectSubmissionLableGroup}
                anchorEl={submissionOptionAnchor}
                open={!!submissionOptionAnchor}
                onClear={clearAllSubmissionOption}
              />
            )}
            {meetingTypeOptionAnchor && !isEmpty(meetingOptions) && (
              <MeetingOptions
                options={meetingOptions}
                onClose={() => setMeetingTypeOptionAnchor(null)}
                onOptionSelect={selectMeetingCategory}
                onGroupSelect={selectMeetingType}
                anchorEl={meetingTypeOptionAnchor}
                onClear={clearAllMeetingOption}
              />
            )}
            <Box alignSelf='center' pl={2} justifySelf='center'>
              {!donePageDataLoadingAnimation ? (
                <Skeleton
                  variant='rectangular'
                  height={16}
                  width={120}
                  sx={{ mr: 'auto', ml: 'auto', borderRadius: 1 }}
                />
              ) : (
                <Typography
                  sx={{ color: 'gray.500', fontWeight: 600, fontSize: 14 }}
                  textAlign='center'>
                  Total {nctIds.length} trials
                </Typography>
              )}
            </Box>
            <Box
              alignSelf='end'
              alignItems='center'
              flex='1'
              display='flex'
              justifyContent='end'
              gap={1}>
              {!donePageDataLoadingAnimation ? (
                <>
                  <Skeleton
                    variant='rectangular'
                    height={24}
                    width={120}
                    sx={{ mr: '10px', borderRadius: 1 }}
                  />
                  <Skeleton
                    variant='rectangular'
                    height={24}
                    width={40}
                    sx={{ mr: 1, borderRadius: 1 }}
                  />
                  <Skeleton
                    variant='rectangular'
                    height={24}
                    width={80}
                    sx={{ mr: 1, borderRadius: 1 }}
                  />
                </>
              ) : (
                <>
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    sx={{ '& .MuiTooltip-tooltipPlacementTop': { margin: '0px !important' } }}>
                    <Tooltip
                      PopperProps={{
                        sx: {
                          zIndex: 1100,
                          '.MuiTooltip-tooltip': {
                            maxWidth: 500
                          }
                        }
                      }}
                      title={`There are no trials matched between Clinical Trials Source and ${
                        source === 'us' ? 'SBAs' : 'SmPCs'
                      }. This is potentially due to mismatch of active ingredient or mismatch of trial details between sources. Please turn off toggle to view other trials`}
                      open={
                        donePageDataLoadingAnimation &&
                        showSBAsTrials &&
                        responseData.nctIds?.length > 0 &&
                        nctIds.length === 0
                      }
                      placement='top'
                      arrow>
                      <Box display='flex' flexDirection='row' alignItems='center'>
                        <Typography sx={showSBAsTrials ? styles.labelSelected : styles.label}>
                          {source === 'us' ? 'Show trials in SBAs' : 'Show trials in SmPCs'}
                        </Typography>
                        <Switch
                          sx={styles.trialsSwitch}
                          checked={showSBAsTrials}
                          onChange={() => setShowSBATrials((prev: boolean) => !prev)}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                  <Divider orientation='vertical' sx={applicationCardStyles.divider} />
                  <ReportButton data={{}} addToReport={handleAddToReport} prefixId='cdp_' />
                  <Divider orientation='vertical' sx={applicationCardStyles.divider} />
                  <Box
                    onClick={() => setFiltersOpen(opn => !opn)}
                    display='inline-flex'
                    alignItems='center'
                    sx={{
                      ...styles.filterButtonContainer,
                      color: isFilterApplied ? 'white.main' : 'primary.700',
                      backgroundColor: isFilterApplied ? 'primary.600' : 'white.main'
                    }}>
                    {isFilterApplied ? (
                      <FilterFilledIcon sx={styles.filterActiveButton} />
                    ) : (
                      <FilterEmptyIcon sx={styles.filterInactiveButton} />
                    )}
                    Filters{' '}
                    {totalNumberOfSelectedFilters > 0 && `(${totalNumberOfSelectedFilters})`}
                  </Box>
                </>
              )}
            </Box>
          </Box>
          {
            // eslint-disable-next-line no-nested-ternary
            !donePageDataLoadingAnimation ? (
              <Box justifySelf='center' alignSelf='center' margin='auto'>
                {/* Loading skeleton */}
                <Loading
                  loading={isLoading || isLoadingOverlays}
                  onDoneAnimation={handleDoneAnimation}
                  source={source}
                />
              </Box>
            ) : donePageDataLoadingAnimation && !responseData.barGraphData && errorMessage ? (
              <Box justifySelf='center' alignSelf='center' margin='auto'>
                <Typography variant='h3'>{errorMessage}</Typography>
              </Box>
            ) : (
              <Box height='100%' position='relative' border='1px solid #E2E2EA'>
                <Suspense fallback={<TimeSeriesGraphSkeleton />}>
                  <TimeSeriesGraph
                    ref={addRef}
                    barGraphData={barGraphData}
                    shapes={responseData.shapes}
                    annotations={responseData.annotations}
                    yAxisIds={nctIds}
                    onHorizontalBarClick={handleHorizontalBarClick}
                    y2AxisIds={responseData.negativeAxisNCTIds}
                    plotConfig={{
                      toImageButtonOptions: {
                        filename: `${activeIngredient}_cdp_plot`
                      }
                    }}
                    source={source}
                  />
                </Suspense>
                {(!isEmpty(selectedGraphEntityData) || !isEmpty(selectedMeeting)) && (
                  <InfoWindow
                    handleViewDocsClose={handleViewDocsClose}
                    mappedEntityData={mappedEntityData}
                    handleViewDocsClick={handleViewDocsClick}
                    meetingData={filteredMeetingsData}
                    selectedMeeting={selectedMeeting}
                    setSelectedMeeting={setSelectedMeeting}
                    setHighlightedMeeting={setHighlightedMeeting}
                  />
                )}
                {donePageDataLoadingAnimation &&
                  responseData.nctIds &&
                  responseData.nctIds.length === 0 && (
                    <Box
                      position='absolute'
                      top='42.5%'
                      left='50%'
                      sx={{ transform: 'translate(-50%, -50%)' }}>
                      <Box flexDirection='column' display='flex' alignItems='center'>
                        <Typography sx={styles.trialsMessage1}>
                          We did not find matching clinical trials from
                        </Typography>
                        <Tooltip
                          title={
                            source === 'us'
                              ? `clinicaltrials.gov and USFDA SBAs for ${activeIngredient}`
                              : `clinicaltrialsregister.eu and EPAR SmPCs for ${activeIngredient}`
                          }>
                          <Typography sx={styles.trialsMessage1}>
                            {source === 'us'
                              ? `clinicaltrials.gov and USFDA SBAs for ${trimText(
                                  activeIngredient,
                                  20
                                )}.`
                              : `clinicaltrialsregister.eu and EPAR SmPCs for ${trimText(
                                  activeIngredient,
                                  20
                                )}.`}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Box
                        flexDirection='column'
                        display='flex'
                        alignItems='center'
                        sx={styles.trialBoxStyle}>
                        <Typography sx={styles.trialsMessage3}>
                          If you think this is an error, share your feedback below.
                        </Typography>
                      </Box>
                    </Box>
                  )}
                {donePageDataLoadingAnimation &&
                  showSBAsTrials &&
                  responseData.nctIds?.length > 0 &&
                  nctIds.length === 0 && (
                    <Box
                      position='absolute'
                      top='42.5%'
                      left='50%'
                      sx={{ transform: 'translate(-50%, -50%)' }}>
                      <Box flexDirection='column' display='flex' alignItems='center'>
                        <Typography sx={styles.trialsMessage1}>
                          {`There are no trials matched between Clinical Trials Source and ${
                            source === 'us' ? 'SBAs' : 'SmPCs'
                          }. This is potentially due to mismatch of active ingredient or mismatch of trial details between sources. Please turn off toggle to view other trials.`}
                        </Typography>
                      </Box>
                      <Box
                        flexDirection='column'
                        display='flex'
                        alignItems='center'
                        sx={styles.trialBoxStyle}>
                        <Typography sx={styles.trialsMessage3}>
                          If you think this is an error, share your feedback below.
                        </Typography>
                      </Box>
                    </Box>
                  )}
                {donePageDataLoadingAnimation &&
                  responseData.negativeAxisNCTIds &&
                  responseData.negativeAxisNCTIds.length === 0 && (
                    <Box
                      position='absolute'
                      bottom='7.5%'
                      left='50%'
                      sx={{ transform: 'translate(-50%, -50%)' }}>
                      <Typography variant='h3'>
                        Could not find {source === 'us' ? 'FDA' : 'EMA'} Approvals.
                      </Typography>
                    </Box>
                  )}
              </Box>
            )
          }
        </Box>
        {showDocs && (
          <Drawer
            anchor='right'
            open={showDocs}
            onClose={handlePDFViewClose}
            sx={styles.documentDrawer}>
            <Box paddingX={2} paddingY={2.5} height='100%'>
              {
                // eslint-disable-next-line no-nested-ternary
                nctId ? (
                  <CTDocuments
                    id={nctId}
                    onClose={handlePDFViewClose}
                    selectedGraphEntityData={selectedGraphEntityData}
                    source={source === 'us' ? 'ct' : 'euctr'}
                  />
                ) : submission ? (
                  <FDADocuments
                    applicationNumber={applicationNumber}
                    submission={submission}
                    onClose={handlePDFViewClose}
                    applicationDetails={applicationDetails}
                    source={source}
                    approvalDate={approvalDate}
                  />
                ) : null
              }
            </Box>
          </Drawer>
        )}
        <Drawer anchor='right' open={isFiltersOpen} onClose={handleFiltersClose}>
          <Stack sx={{ width: 310, height: '100%' }}>
            <Box overflow='hidden' boxShadow='rgb(0 0 0 / 3%) 0px 5px 10px'>
              <Box pl={1}>
                <Stack
                  direction='row'
                  display='flex'
                  justifyContent='space-between'
                  pt={2}
                  pb={1}
                  pl={2}>
                  <Typography
                    color='primary'
                    sx={{
                      fontWeight: 'bold',
                      cursor: 'pointer'
                    }}
                    variant='h4'>
                    Filters
                  </Typography>
                </Stack>
                <Tabs value={activeFilter} onChange={handleTabChange} aria-label='Filter Tabs'>
                  <Tab
                    label={
                      <Badge variant='dot' color='primary' invisible={!ctFiltersApplied}>
                        TRIALS
                      </Badge>
                    }
                    value='TRIALS'
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...a11yProps('TRIALS')}
                  />
                  <Tab
                    label={
                      <Badge
                        variant='dot'
                        sx={{
                          '& .MuiBadge-badge': {
                            color: 'blue.lightNavy',
                            backgroundColor: 'blue.lightNavy'
                          }
                        }}
                        invisible={!fdaFiltersApplied}>
                        {source === 'us' ? 'FDA' : 'EMA'}
                      </Badge>
                    }
                    value='FDA'
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...a11yProps('FDA')}
                  />
                </Tabs>
              </Box>
              {activeFilter === 'TRIALS' ? (
                <Filters
                  filters={trialsFilters || initialTrialsFilter}
                  onApply={handleFilterApply}
                  onClear={handleClearFilters}
                  loading={isLoading || isLoadingOverlays}
                  setFilterValidity={setCTFilterValid}
                  elementProps={trialsFilterElementProps}
                />
              ) : (
                <Filters
                  filters={sourceSubmissionFilters || initialSourceSubmissionFilter}
                  onApply={handleFilterApply}
                  onClear={handleClearFilters}
                  loading={isLoading || isLoadingOverlays}
                  setFilterValidity={setFDAFilterValid}
                  elementProps={fdaFilterElementProps}
                />
              )}
            </Box>
          </Stack>
        </Drawer>
        <Snackbar
          open={!!warningMessage}
          autoHideDuration={6000}
          onClose={handleOnAlertClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}>
          <Alert onClose={handleOnAlertClose} severity='error' sx={{ width: '100%' }}>
            <Typography>{warningMessage}</Typography>
          </Alert>
        </Snackbar>
      </Box>
      <Box pl={1}>
        <Typography
          display='inline-block'
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            '&:hover': { color: 'primary.main' }
          }}
          onClick={handleFeedback}>
          Share your feedback
        </Typography>
      </Box>
      <FeedbackDialog
        open={isFeedbackOpen}
        appNumber={applicationNumber}
        tradeName={tradeName}
        onClose={() => setFeedbackOpen(false)}
      />
      {modalOpen === 'cdp' && <CdpSearchBar modalOpen={modalOpen} setModalOpen={setModalOpen} />}
    </Box>
  );
};

export default React.memo(CDPMain);

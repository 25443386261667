import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent, IconButton, Stack, Typography, Tooltip } from '@mui/material';

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import DraftableComparisonView from '../../../components/Comparison/DraftableComparisonView';

import comparisonClasses from '../../../components/Comparison/styles/LabelSelection.style';
import compareDialogStyles from '../styles/CompareDialog';

import { getLabelComparision } from '../../../api/pages/Reg360';
import getComparisonFile from '../../../helpers/draftableUtils';
import { LeftArrowIcon } from '../../../assets/svgs/Icons';
import ReportingDrawer from '../../../components/Report/ReportingDrawer';

const ComparisonDialog: React.FC<any> = ({ open, compareItems, onClose, changeGuidances }) => {
  const [viewURL, setViewerURL] = useState('');
  const [openReporting, setOpenReporting] = React.useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (compareItems.length === 2 && open) {
        const res = await getLabelComparision({
          parent_object: compareItems[0]?.pdfLink,
          child_object: compareItems[1]?.pdfLink
        });
        if (res.data.success !== null) {
          setViewerURL(res.data.success);
        }
      }
    })();
  }, [compareItems, open]);

  const downloadComparison = () => {
    try {
      const identifierInURL = viewURL?.split('/')[7];
      const identifier = identifierInURL?.split('?')[0];
      getComparisonFile(identifier);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  if (openReporting) {
    return (
      <ReportingDrawer
        isDrawerOpen={openReporting}
        closeDrawer={() => {
          setOpenReporting(false);
        }}
        isNavBar
      />
    );
  }

  return (
    <Dialog
      fullScreen
      fullWidth
      maxWidth='lg'
      aria-labelledby='customized-dialog-title'
      sx={comparisonClasses.dialog}
      open={open}
      onClose={onClose}>
      <Stack direction='row' sx={compareDialogStyles.headerWrapper}>
        <Stack
          direction='row'
          spacing={0.75}
          onClick={changeGuidances}
          sx={compareDialogStyles.changeGuidancesButtonContainer}>
          <LeftArrowIcon sx={compareDialogStyles.leftArrowIcon} />
          <Typography sx={compareDialogStyles.changeGuidancesText}>Change Guidances</Typography>
        </Stack>
        <Stack direction='row' sx={compareDialogStyles.guidanceComparisonContainer}>
          <Typography sx={compareDialogStyles.guidanceComparisonText}>
            Guidance Comparison
          </Typography>
        </Stack>
        <Tooltip title='Close'>
          <IconButton
            aria-label='delete'
            onClick={onClose}
            size='small'
            sx={compareDialogStyles.iconButton}>
            <HighlightOffOutlinedIcon
              fontSize='inherit'
              sx={comparisonClasses.highlightOffOutlinedIcon}
            />
          </IconButton>
        </Tooltip>
      </Stack>
      <DialogContent sx={comparisonClasses.dialogContent}>
        <DraftableComparisonView
          viewerURL={viewURL}
          downloadFile={downloadComparison}
          parentPdfLink={compareItems[0]?.pdfLink}
          childPdfLink={compareItems[1]?.pdfLink}
          parentApplicationNumber={compareItems[0]?.title}
          childApplicationNumber={compareItems[1]?.title}
          parentApplicationName={compareItems[0]?.title}
          childApplicationName={compareItems[1]?.title}
          setOpenReporting={setOpenReporting}
          enableChatRia
          comparsionType='guidance'
        />
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ComparisonDialog);
